import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeStyle as _normalizeStyle, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-981db0a2"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "column" }
const _hoisted_2 = { class: "ui fluid card" }
const _hoisted_3 = { class: "card-header" }
const _hoisted_4 = {
  viewBox: "0 0 100 100",
  class: "flag-icon"
}
const _hoisted_5 = ["xlink:href"]
const _hoisted_6 = { class: "ui tiny progress success" }
const _hoisted_7 = { class: "card-content" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        (_openBlock(), _createElementBlock("svg", _hoisted_4, [
          _createElementVNode("use", {
            "xlink:href": `#${_ctx.server.region}`
          }, null, 8, _hoisted_5)
        ])),
        _createElementVNode("span", null, _toDisplayString(_ctx.server.name), 1),
        _createElementVNode("p", null, _toDisplayString(_ctx.server.type), 1)
      ]),
      _createElementVNode("div", _hoisted_6, [
        _createElementVNode("div", {
          class: "bar",
          style: _normalizeStyle({width: _ctx.getStatus ? `${_ctx.getRAMStatus.toString()}%` : '0%'})
        }, null, 4)
      ]),
      _createElementVNode("div", _hoisted_7, [
        _createElementVNode("p", null, "Network: " + _toDisplayString(`${_ctx.tableRowByteConvert(_ctx.server.network_rx)} | ${_ctx.tableRowByteConvert(_ctx.server.network_tx)}`), 1),
        _createElementVNode("p", null, "负载状态: " + _toDisplayString(typeof _ctx.server.load !== 'undefined' ? _ctx.server.load : 'Offline'), 1)
      ])
    ])
  ]))
}