import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { id: "cards" }
const _hoisted_2 = { class: "ui doubling three column grid" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CardItem = _resolveComponent("CardItem")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.servers, (server, index) => {
        return (_openBlock(), _createBlock(_component_CardItem, {
          key: index,
          server: server
        }, null, 8, ["server"]))
      }), 128))
    ])
  ]))
}