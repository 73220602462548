import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-30a393ce"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  class: "bar",
  style: {"width":"100%"}
}
const _hoisted_2 = { class: "expandRow" }
const _hoisted_3 = { colspan: "12" }
const _hoisted_4 = { id: "expand_mem" }
const _hoisted_5 = { id: "expand_swap" }
const _hoisted_6 = { id: "expand_hdd" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("tr", {
      class: "tableRow",
      onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.collapsed = !_ctx.collapsed))
    }, [
      _createElementVNode("td", null, [
        _createElementVNode("div", {
          class: _normalizeClass(["ui progress", {'success': _ctx.getStatus, 'error': !_ctx.getStatus}])
        }, [
          _createElementVNode("div", _hoisted_1, [
            _createElementVNode("span", null, _toDisplayString(_ctx.getStatus ? '运行中' : '维护中'), 1)
          ])
        ], 2)
      ]),
      _createElementVNode("td", null, _toDisplayString(_ctx.server.name), 1),
      _createElementVNode("td", null, _toDisplayString(_ctx.server.type), 1),
      _createElementVNode("td", null, _toDisplayString(_ctx.server.location), 1),
      _createElementVNode("td", null, _toDisplayString(_ctx.server.uptime || '–'), 1),
      _createElementVNode("td", null, _toDisplayString(_ctx.getStatus ? _ctx.server.load : '-'), 1),
      _createElementVNode("td", null, _toDisplayString(_ctx.getStatus ? `${_ctx.tableRowByteConvert(_ctx.server.network_rx)} | ${_ctx.tableRowByteConvert(_ctx.server.network_tx)}` : '–'), 1),
      _createElementVNode("td", null, _toDisplayString(_ctx.getStatus ? `${_ctx.tableRowByteConvert(_ctx.server.network_in)} | ${_ctx.tableRowByteConvert(_ctx.server.network_out)}` : '–'), 1),
      _createElementVNode("td", null, [
        _createElementVNode("div", {
          class: _normalizeClass(["ui progress", _ctx.getProcessBarStatus(_ctx.getCpuStatus)])
        }, [
          _createElementVNode("div", {
            class: "bar",
            style: _normalizeStyle({'width': `${_ctx.getCpuStatus.toString()}%`})
          }, _toDisplayString(_ctx.getStatus ? `${_ctx.getCpuStatus.toString()}%` : '维护中'), 5)
        ], 2)
      ]),
      _createElementVNode("td", null, [
        _createElementVNode("div", {
          class: _normalizeClass(["ui progress", _ctx.getProcessBarStatus(_ctx.getRAMStatus)])
        }, [
          _createElementVNode("div", {
            class: "bar",
            style: _normalizeStyle({'width': `${_ctx.getRAMStatus.toString()}%`})
          }, _toDisplayString(_ctx.getStatus ? `${_ctx.getRAMStatus.toString()}%` : '维护中'), 5)
        ], 2)
      ]),
      _createElementVNode("td", null, [
        _createElementVNode("div", {
          class: _normalizeClass(["ui progress", _ctx.getProcessBarStatus(_ctx.getHDDStatus)])
        }, [
          _createElementVNode("div", {
            class: "bar",
            style: _normalizeStyle({'width': `${_ctx.getHDDStatus.toString()}%`})
          }, _toDisplayString(_ctx.getStatus ? `${_ctx.getHDDStatus.toString()}%` : '维护中'), 5)
        ], 2)
      ])
    ]),
    _createElementVNode("tr", _hoisted_2, [
      _createElementVNode("td", _hoisted_3, [
        _createElementVNode("div", {
          class: _normalizeClass({collapsed: _ctx.collapsed}),
          style: _normalizeStyle({'max-height': _ctx.getStatus ? '' : '0'})
        }, [
          _createElementVNode("div", _hoisted_4, "内存信息: " + _toDisplayString(_ctx.getStatus ? `${_ctx.expandRowByteConvert(_ctx.server.memory_used * 1024)} / ${_ctx.expandRowByteConvert(_ctx.server.memory_total * 1024)}` : '–'), 1),
          _createElementVNode("div", _hoisted_5, "交换分区: " + _toDisplayString(_ctx.getStatus ? `${_ctx.expandRowByteConvert(_ctx.server.swap_used * 1024)} / ${_ctx.expandRowByteConvert(_ctx.server.swap_total * 1024)}` : '–'), 1),
          _createElementVNode("div", _hoisted_6, "硬盘信息: " + _toDisplayString(_ctx.getStatus ? `${_ctx.expandRowByteConvert(_ctx.server.hdd_used * 1024 * 1024)} / ${_ctx.expandRowByteConvert(_ctx.server.hdd_total * 1024 * 1024)}` : '–'), 1)
        ], 6)
      ])
    ])
  ], 64))
}