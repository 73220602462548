import { resolveComponent as _resolveComponent, createVNode as _createVNode, vShow as _vShow, withDirectives as _withDirectives, createElementVNode as _createElementVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "container" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_the_header = _resolveComponent("the-header")!
  const _component_the_error = _resolveComponent("the-error")!
  const _component_servers_table = _resolveComponent("servers-table")!
  const _component_update_time = _resolveComponent("update-time")!
  const _component_servers_card = _resolveComponent("servers-card")!
  const _component_the_footer = _resolveComponent("the-footer")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_the_header),
    _withDirectives(_createVNode(_component_the_error, null, null, 512), [
      [_vShow, !_ctx.servers]
    ]),
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_servers_table, { servers: _ctx.servers }, null, 8, ["servers"]),
      _createVNode(_component_update_time, { updated: _ctx.updated }, null, 8, ["updated"]),
      _createVNode(_component_servers_card, { servers: _ctx.servers }, null, 8, ["servers"])
    ]),
    _createVNode(_component_the_footer)
  ], 64))
}